import { Link } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Image from "./image"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { withTheme } from "@material-ui/core/styles"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"

import AnchorLink from "react-anchor-link-smooth-scroll"

export const useStyles = makeStyles({
  paper: {
    background: "linear-gradient(45deg, #ffc551 50%, #ffcf70 95%)",
  },
})

const Header = ({ siteTitle, theme }) => {
  const classes = useStyles()
  const styles = {
    header: {
      background: theme.palette.primary.main,
      marginBottom: theme.spacing.unit,
    },
    list: {
      width: 300,
      padding: 5 * theme.spacing.unit,
    },
    root: {
      flexGrow: 1,
    },
    grow: {
      padding: 1 * theme.spacing.unit,
    },
    flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flex: 1,
    },
    button: {
      fontFamily: "'Mango', sans-serif",
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    text: {
      color: "#fff",
      fontFamily: "'Mango', sans-serif",
      fontSize: theme.typography.h6.fontSize,
      textAlign: "left",
    },
    link: {
      textDecoration: "none",
      display: "block",
    },
  }

  const [drawer, setDrawer] = useState(false)

  const handleClose = () => {
    setDrawer(false)
  }

  const handleOpen = () => {
    setDrawer(true)
  }

  const menuItems = [
    { text: "Over", link: "#over" },
    // { text: "Line Up", link: "#lineup" },
    { text: "Locatie", link: "#locatie" },
    { text: "Veelgestelde Vragen", link: "#faq" },
  ]

  const sideList = (
    <div style={styles.list}>
      {menuItems.map((item, index) => (
        <AnchorLink key={index} href={item.link} style={styles.link}>
          <Button onClick={handleClose} role="button" style={styles.text}>
            {item.text}
          </Button>
        </AnchorLink>
      ))}
      <a href="https://shops.link2ticket.nl/13H7aa" style={styles.link}>
        <Button onClick={handleClose} role="button" style={styles.text}>
          Tickets
        </Button>
      </a>
    </div>
  )

  return (
    <div>
      <AppBar color="primary" position="static">
        <Hidden xsDown implementation="css">
          <Toolbar>
            <div style={styles.flex} />
            <div style={styles.grow}>
              <Link to="/">
                <Image />
              </Link>
            </div>
            <div style={styles.flex}>
              <Button
                color="inherit"
                size="large"
                href="https://www.instagram.com/knsrbeach/?hl=nl"
                style={styles.button}
              >
                Instagram
              </Button>
              <Button
                onClick={handleOpen}
                color="inherit"
                size="large"
                style={styles.button}
              >
                Menu
              </Button>
            </div>
          </Toolbar>
        </Hidden>
        <Hidden smUp implementation="css">
          <Toolbar>
            <div style={styles.grow}>
              <Link to="/">
                <Wave />
              </Link>
            </div>
            <div style={styles.flex}>
              <IconButton
                onClick={handleOpen}
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
              >
                <MenuIcon style={{ fontSize: 45 }} />
              </IconButton>
            </div>
          </Toolbar>
        </Hidden>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <div onKeyDown={handleClose}>{sideList}</div>
      </Drawer>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  theme: PropTypes.object.isRequired,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withTheme()(Header)

const Wave = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "krabbetje.png" }) {
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <Img fixed={data.placeholderImage.childImageSharp.fixed} />}
  />
)
